.maccs-internship-institution-wrapper {
  display: flex;
  align-items: center;

  .ant-select {
    &:first-child {
      width: 75% !important;
    }

    &:last-child {
      width: 25% !important;
    }
  }
}

.macs-internship-assistant-wrapper {
 display: flex;
 align-items: center;
 
 .ant-select {
    &:first-child {
      width: 75% !important;
    }

    &:last-child {
      width: 25% !important;
    }
  }
}