.simple-data-table-container {
  .table-container {
    width: 100%;
    height: 100%;
    overflow-x: auto;

    .simple-data-table {
      margin-top: 10px;
  
      width: 100%;
    
      thead {
        th {
          font-weight: bold;
          border-bottom: solid 2px darkgray;
          padding-left: 10px;
        }
      }
    
      tbody {
        tr {
          &:nth-child(odd) {
            background-color: whitesmoke;
          }
          &:hover {
            background-color: #cde9ff;
          }
        }
    
        td {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 10px;
          border-bottom: solid 1px lightgray;
        }
      }
    }
  }

  .simple-data-table-footer {
    margin-top: 10px;
  }
}