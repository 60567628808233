$RIGHT_PADDING: 2.5rem;
$BLUE: #224a95;
$RED: red;

.anonymous-form-wrapper {
  height: 100vh;

  .form-bg {
    background-color: #b99e8d;
    height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: -1;

    img {
      height: 100%;
    }
  }

  .language-selector-row {
    padding: 0 $RIGHT_PADDING;
    justify-content: flex-end;
    margin-top: 10px;

    button {
      margin: 0;
    }
  }

  .form-grid {
    display: flex;
    justify-content: center;
    padding: 0 $RIGHT_PADDING;

    .right-img {
      width: 580px;
      height: 551px;
      margin: 0;
      padding: 0 ! important;
      position: relative;
      overflow: hidden;
    }

    .right-img img {
      position: absolute;
      height: 551px;
    }

    .border-radius-left,
    .border-radius-right {
      border-radius: 10px;
    }

    @media (max-width: 1250px) {
      .right-img {
        display: none;
      }
    }
    @media (min-width: 1250px) {
      .border-radius-left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .border-radius-right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .form-wrapper {
      background-color: white;
      padding: 30px;
      margin-bottom: 20px;
      box-shadow: 0px 0px 1px rgb(0, 0, 0);

      .logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin-right: 10px;
          height: 75px;
        }

        .title-wrapper {
          position: relative;

          .version {
            position: absolute;
            right: -10px;
            bottom: -5px;
          }
        }
      }

      .forgot-password-link {
        color: rgba(0, 0, 0, 0.65) !important;
      }

      .login-button {
        background: $RED !important;
        border-color: $RED !important;
        font-size: 16px;
        font-weight: bold;
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $BLUE !important;
          border-color: $BLUE !important;
        }

        &:after {
          border: 1px solid transparent !important;
        }
      }
    }
  }

  .opal-logo-form {
    position: absolute;
    right: 0;
    bottom: -40px;
    width: 117px;
  }

  .bottom-information {
    height: 140px;
    flex-shrink: 0;
    display: flex;
    padding-right: $RIGHT_PADDING;
    min-height: 180px;

    .opal-logo {
      img {
        width: 140px;
      }
    }
  }

  .changelog-link {
    bottom: 10px;
    width: 100%;
    padding: 15px;
    justify-content: flex-end;
  }
}

#launcher.on-login-page {
  margin-right: 2rem !important;
}
