.evaluation-details-drawer {

  .ant-drawer-wrapper-body,
  & .ant-drawer-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
}

.evaluation-details-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .student-evaluations-list-header {
    margin: 30px 0 15px 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    font-family: 'Roboto', 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .top-details {
    display: flex;
    align-items: flex-start;
    border: 1px solid #3c8dbc;
    padding: 10px;

    .student-details {
      flex: 1;
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 5px 0;

        label {
          font-weight: bold;
          color: #3c8dbc;
        }

        span {
          text-align: right;
        }
      }
    }

    .general-satisfaction-ratios {
      flex: 1;
      padding-left: 20px;
      display: flex;
      align-items: center;


      .satisfaction-block {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 0 25px;
        justify-content: center;

        label {
          font-weight: bold;
          color: #3c8dbc;
          padding: 5px 0;
          width: 100%;
          text-align: center;
        }

        .satisfaction-ratio {
          justify-content: center;

          .tooltip-wrapper {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }

  .evaluations-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;

    .evaluation-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px 0 10px 0;
      border-top: 1px solid #eee;

      &:first-child {
        border-top: 0;
      }

      .evaluation-item-header {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: flex-end;

        .internship-duration {
          font-size: 16px;
          font-weight: bold;
          color: #333;
        }

        .internship-info {
          position: absolute;
          left: 0;
          max-width: 160px;
          font-weight: bold;
        }
      }

      .evaluation-item-subheader {
        font-weight: bold;
      }

      .evaluation-item-details {
        width: 100%;
        display: flex;
        padding: 10px 10px 10px 25px;
        justify-content: space-between;
        position: relative;

        div {
          flex: 1;

          &.left-space {
            padding-left: 15px;
          }
        }

        label {
          color: #bbb;
          font-weight: bold;
        }

        .expand-details {
          position: absolute;
          left: -5px;
          background: none;
          border: none;
          outline: none;
          cursor: pointer;
        }

        .satisfaction-ratio {
          .tooltip-wrapper {
            span:first-child {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}