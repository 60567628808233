.profile-page {
  font-size: 14px;

  p{
    white-space: pre-wrap;
    margin-bottom: 0px;
  }
}

.profile-page-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2%;
  padding-left: 7%;
  background-color: #357fa9;

  .profile-page-image {
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      cursor: pointer;

      .profile-picture-overlay {
        opacity: 1;
      }
    }
  }

  .profile-page-title {
    margin-left: 50px;
    align-items: flex-start;  

    h1{
      color: white;
    }
  
    h2{
      font-size: 16px;
      color: white;
    }
  }
}

.profile-picture-overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  height: 50%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 30px;
  padding: 10px;
  text-align: center;
  border-radius: 0px 0px 62.5px 62.5px;
}

.profile-page-personnal {
  padding: 24px 50px 24px 100px;
  padding-right: 10%;
  display: flex;
  flex-direction: column;

  .inner-margin {
    margin-bottom: 5px;
  }
}

.profile-page-card-div {
  min-width: 350px;
  margin-bottom: 15px;
}


.profile-page-privacy {
  padding: 24px 100px 24px 50px;
  padding-left: 10%;
  display: flex;
  flex-direction: column;
}

#file-getter-header {
  margin-top: 10px;
}

.fit-content {
  width: fit-content;
}

#modal_size {
  width: 80%;
  height: 80%;
}

.carpooling-region-container {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  
  .carpooling-region-title {
    padding: 15px 25px 15px 25px;
    background-color: #fafafa;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-left: 5px;
      }
    }

    h3 {
      margin: 0px;
    }
  }

  .carpooling-region-information {
    display: flex;
    flex-direction: row;
    padding: 0px 24px 16px 24px;
  }

  .carpooling-region-body {
    display: flex;
    flex-direction: row;
    justify-content: center;

    padding: 0px 25px 15px 25px;
  }
}
  
@media (max-width:900px) {
  .profile-page {
    min-width: 300px;
  }

  .profile-page-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .profile-page-image {
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      &:hover {
        cursor: pointer;
      }
    }
  
    .profile-page-title {
      display: flex;
      flex-direction: column;
      margin-left: 0px;
      align-items: center;  
  
      h1 {
        text-align: center;
      }

      h2 {
        text-align: center;
      }
    }
  }

  .profile-page-card-div {
    min-width: 300px;
  }  

  .profile-page-personnal {
    padding: 10px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .inner-margin {
      margin-bottom: 5px;
    }
  }

  .profile-page-card-div {
    width: 80%;
    margin-bottom: 10px;
  }

  .profile-page-privacy {
    padding: 0px 10px 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.profile-page-container {
  .account-informations-container {
    h2 {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid lightgrey;
    }

    button {
      margin-bottom: 10px;
    }

    .information {
      display: flex;
      flex-direction: column;
      padding-left: 4%;

      h3 {
        margin-bottom: 5px;
      }

      span {
        margin-left: 5px;
        margin-bottom: 10px;
      }
    }
  }
}