

.maccs-internships-manager {
  width: 100%;
  height: calc(100vh - 180px);

  .manager-table-container {
    width: 100%;
    height: 100%;
    overflow-x: auto;

    table.manager-table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 5px;
      table-layout: fixed;
      top: 0;
      position: sticky;
    
      thead tr th {
        background: white;
        z-index: 1;
        text-align: center;
    
        &.is-current-week div {
          border-top: 2px solid #3c8dbc;
        }
    
        &.highlighted {
          background-color: #cde9ff;
        }
    
        &:not(.is-current-week) div {
          margin-top: 2px;
        }
    
        &.week {
          width: 43px;
          /* If this value is changed, it has to be also updated in the const of the InternshipsManager[s]. */
        }
    
        &.month {
          width: 70px;
        }

        &:first-child {
          width: 350px;
          position: sticky;
          left: 0px;
          z-index: 2;
        }
      }
    
      tbody {
        tr {
          height: 74px;

          &:nth-child(even) {
            background-color: #ececec;
          }
    
          td {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: center;
            padding: 1px 0px 1px 0px;
    
            &.has-internship {
              & > div {
                text-align: center;
                height: 38px;
                border-top: 2px solid #3c8dbc;
                border-bottom: 2px solid #3c8dbc;
              }
    
              div.underloaded {
                background: #7eb73e;
              }
    
              div.balanced {
                background: #3c8dbc;
              }
    
              div.overloaded {
                background: #ff5722;
              }
            }
    
            & > div.actionnable {
              cursor: pointer;
            }
    
            div.actionnable {
              .fa-plus {
                margin-top: 11px;
              }
    
              .fa-trash {
                margin-top: 10px;
              }
            }
    
            &.has-internship div.actionnable {
              .fa-arrow-left {
                margin-top: 10px;
                margin-left: 15px;
              }
    
              .fa-arrow-right {
                margin-top: 10px;
                margin-right: 15px;
              }
            }
    
            &:not(.has-internship) div.actionnable {
              .fa-arrow-left {
                margin-top: 5px;
                margin-left: 15px;
              }
    
              .fa-arrow-right {
                margin-top: 5px;
                margin-right: 15px;
              }
            }
    
            .ant-select {
              width: 100%;
            }
    
            .ant-select.narrow {
              .ant-select-selection {
                height: 25px;
              }
    
              .ant-select-selection__rendered {
                line-height: 25px;
              }
            }
    
            &.has-internship.has-internship-end > div {
              border-right: 2px solid #3c8dbc;
              border-bottom-right-radius: 3px;
              border-top-right-radius: 3px;
            }
    
            &.has-internship.has-internship-start > div {
              border-left: 2px solid #3c8dbc;
              border-bottom-left-radius: 3px;
              border-top-left-radius: 3px;
            }

            &:first-child {
              width: 350px;
              overflow: unset;
              position: sticky;
              left: 0px;
              z-index: 2;
            }
          }
    
          &:hover {
            td {
              background-color: #cde9ff;
            }
          }
        }
      }
    
      td > .ant-badge {
        left: -3px;
        position: relative;
        top: -9px;
        width: 0px;
      }
    }

    .internship-card-old {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: white;
      height: 110px;
  
      .quick-menu-container {
        width: 50px;
      }
  
      .status-validation-wrapper {
        width: 50px;
      }
  
      .internship-info-old {
        width: 450px;
        overflow-x: hidden;
      }
    }

    .internship-card-old {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: white;
      height: 110px;
  
      .quick-menu-container {
        width: 50px;
      }
  
      .status-validation-wrapper {
        width: 50px;
      }
  
      .internship-info-old {
        width: 450px;
        overflow-x: hidden;
      }
    }
  
    .internship-card-old-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: white;
      height: 90px;
      margin: 10px 0px 10px 0px;
    }
  

    .internship-card-extended {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: white;
    
      .quick-menu-container {
        width: 50px;
      }
    
      .status-validation-wrapper {
        width: 50px;
      }
    
      .internship-info-old {
        height:100%;
        width: calc(100% - 100px);
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;

        svg {
          margin-right: 5px;
        }
      }
    }
  
    tr {
      &:nth-child(even) {
        .internship-card-old {
          background: #ececec;
        }
      }
    }
  }
}

.internship-data-modal {
  .ant-modal-content {
    .ant-modal-body {
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 2px;
        margin-bottom: 2px;

        .ant-calendar-picker-input {
          margin-left: 8px;
        }
      }
    }
  }
}