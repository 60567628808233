.maccs-shifts-manager {
  width: 100%;
  height: calc(100vh - 210px);

  .shifts-manager-body{
    width: 100%;
    height: 100%;

    table {
      width: 100%;
      height: calc(100vh - 210px);
      display: block;
      max-width: 100%;
      border-collapse: separate;
      border-spacing: 0px 10px;
      margin-top: 5px;
      table-layout: fixed;
      table-layout: fixed;
      overflow-x: auto;
      overflow-y: auto;

      thead tr th {
        background: white;
        z-index: 1;
        text-align: center;

        &:first-child {
          background: white;
          position: sticky;
          left: 0;
          min-width: 200px;
          z-index: 99;
        }
      }

      th {
        &:nth-child(n + 1) {
          position: sticky;
          top: 0px;
          min-width: 60px;
          cursor: pointer;
        }
      }


      tbody {
        tr {
          td {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: center;
            padding: 1px 0px 1px 0px;

            &:first-child {
              background-color: white;
              position: sticky;
              left: 0;
              min-width: 200px;
              z-index: 99;
              border: 2px solid lightgrey;
              box-shadow: -10px 0px  white;
              border-radius: 5px;
            }

            &:not(:first-child) {
              padding: 3px;
              height: 75px;
              min-width: 80px;
            }
          }

          &:nth-child(even) {
            background-color: #ececec;

            td {
              background-color: #ececec;
            }
          }

          &:hover {
            td {
              background-color: #cde9ff;
            }
          }
        }
      }

      .empty-body {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: large;
      }
    }
  }

  .maccs-shiftsmanager-header-container {

    .actions {
      display: flex;
      flex-direction: row;
    }

    .legends {
      margin-top: 10px;
      font-size: 11px;
      color: #bbb;
      cursor: default;
      max-width: 600px;

      &:first-child {
        margin-bottom: 5px;
      }

      b {
        color: #357fa9;
      }
    }
  }

  .footer-container {
    display: flex;
    flex-direction: row;
    margin-right: 130px;
  }
}

.internship-card-container {
  width: 300px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .internship-card-title {
    white-space: normal;
    display: flex;
    align-items: center;

    .internship-information {
      display: flex;
      flex-direction: column;
      text-align: left;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;

        span {
          &:first-child {
            font-size: small;
          }
        }
      }

      .regular-image {
        display: flex;
        margin-right: 5px;
        min-width: 20px;
        justify-content: center;
      }

      .last-text {
        font-size: small;
        color: grey;
      }
    }
  }

  .internship-card-buttons {
    button {
      margin-top: 0px;
    }

    font-size: 12px;
  }
}

.maccs-shift-cell {
  height: 100%;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  border-radius: 5px;

  .maccs-shift-cell-time {
    padding: 5px;
    background-color: #A7C7E7;
    background-color: #C3EEFA;
    font-size: 11px;
    font-weight: bold;
    border-radius: 10px;
  }

  .maccs-shift-cell-code {
    font-weight: bold;
  }
}

.event-code {
  border: 2px solid #87C4FF;
}

.absent-event-code {
  border: 2px solid red;
}
