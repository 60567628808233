.form-item{
  margin-bottom: 1.5vh;
}

.form-label {
  font-weight: bolder;
  color: black;
}

.form-footer {
  padding-top: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.form-select {
  display: flex;
  flex-direction: column;
}

.form-alert {
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
}