.internships-manager-header {
  .actions-and-filters {
    display: flex;
    flex-direction: row;    
  }

  .new-messages-notification-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    padding: 0 4px;
    margin: 0;
    margin-left: 10px;
  
    svg {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.65);
    }
  
    .unread-messages {
      position: absolute;
      border-radius: 100px;
      background: red;
      color: #fff;
      font-weight: bold;
      padding: 10px;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      top: -6px;
      right: -15px;
    }
  
    &.small-text {
      .unread-messages {
        font-size: 8px;
      }
    }
  }

  .table-modifier-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
}

.internship-manager-table {
  width: 100%;
  overflow-x: auto;

  thead {
    th {
      &:first-child {
        position: sticky;
        left: 0px;
        z-index: 2;
      }
    }
  }

  tbody {
    td {
      &:first-child {
        overflow: unset;
        position: sticky;
        left: 0px;
        z-index: 2;
      }
    }
  }

  .internship-card-old {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    height: 110px;

    .quick-menu-container {
      width: 50px;
    }

    .status-validation-wrapper {
      width: 50px;
    }

    .internship-info-old {
      width: 450px;
      overflow-x: hidden;
    }
  }

  .internship-card-old-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    height: 90px;
    margin: 10px 0px 10px 0px;
  }

}