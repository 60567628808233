.message-box {
  position: fixed;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  width: 300px;
  box-shadow: 0px 0px 10px 0px #607d8b;
  max-height: 0;
  transition: all 0.3s ease-out;

  &.displayed {
    max-height: 1000px;
  }

  .message-box-header {
    background: var(--primary-color);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background: #3579a0;
    }

    .title {
      font-weight: 600;
      letter-spacing: 1px;
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
      }
    }

    span {
      display: flex;
      background: transparent;
      outline: none;
      border: 0;
      padding: 0;
      margin: 0;
    }
  }

  .filter-box-wrapper {
    display: flex;

    .option-group {
      padding: 15px 5px;
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      min-height: 100%;
      justify-content: space-between;

      &:first-child {
        border-right: 1px solid #ddd;
      }

      label {
        text-transform: uppercase;
        font-size: 10px;
        text-align: center;
        margin-bottom: 5px;
        font-weight: bold;
      }

      .sort-button {
        font-size: 16px;
        background: transparent;
        outline: none;
        border: 1px solid #555;
        border-radius: 100px;
        cursor: pointer;
        color: #555;
        transition: all 0.3s ease-out;

        &.up {
          transform: rotate(180deg);
        }
      }
    }
  }

  .separation-line {
    height: 1px;
    background: #ddd;
  }

  .messages-list {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 600px;
    overflow: auto;

    li {
      display: flex;
      padding: 0 15px;
      cursor: pointer;

      &:hover {
        background: rgba(60, 141, 188, 0.1);
      }

      .message-item {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #ddd;
        padding: 10px 0;

        &.new-messages {
          .message-text > div {
            font-weight: bold !important;
            color: #000;
          }
        }

        .message-text {
          display: flex;
          flex-direction: column;

          .inner-message-row {
            display: flex;
            align-items: center;
            flex: 1;

            .message-icon {
              font-size: 11px;
              color: #555;
              margin-right: 5px;
            }

            .inner-message-text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 210px;
            }
          }
        }
      }
    }
  }

  .message-count-circle {
    border-radius: 100px;
    background: red !important;
    color: #fff;
    font-weight: bold;
    padding: 12px !important;
    width: 24px;
    height: 24px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }

  .no-message-notification {
    padding: 20px 15px;
    display: flex;
    justify-content: center;
    font-weight: bold;
  }
}
